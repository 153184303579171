import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import startApp from './App';

i18n
  .use(initReactI18next)
  .init({
    lng: 'en',

    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,

    // do not load a fallback
    fallbackLng: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  }).then(startApp);