/* global gtag */

const useGoogleTagManager = () => {
  const trackClickToRegister = (url) => {
    var callback = function () {
      if (typeof (url) != 'undefined') {
        window.location = url;
      }
    };
    gtag('event', 'conversion', {
      'send_to': 'AW-10951312400/uGk5CO_xmtADEJCI_-Uo',
      'event_callback': callback
    });
  };

  return { trackClickToRegister };
};

export default useGoogleTagManager;
