import _ from 'lodash';

import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import {
  HashRouter,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import { Box, Button, Container, Link, Snackbar, ThemeProvider, Typography, useMediaQuery } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Slide from '@material-ui/core/Slide';

import theme from './theme';
import LandingPage from './components/pages/LandingPage';
import PricingPage from './components/pages/PricingPage';
import Navbar from './components/Navbar';
import GDPRIcon from './footer/GDPR.svg';
import SSLIcon from './footer/SSL.svg';

import useAnalytics from './hooks/useAnalytics';
import useCookieConsent from './hooks/useCookieConsent';

const TransitionUp = props => <Slide {...props} direction="up" />;

const CookieSnackbar = ({ open, onConsented }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const action = (
    <Box>
      <Button color="primary" size="medium" onClick={onConsented}>
        {t('Agree and close')}
      </Button>
    </Box>
  );

  const message = (
    <Container maxWidth="sm">
      <Typography variant="body1">
        <Trans>
          By clicking 'Agree and close' you agree to our use of cookies for technical, analytical, and marketing purposes.
          Please consult our <Link href={process.env.REACT_APP_CANVASS_PRIVACY_POLICY_HREF} target="_blank">Privacy Policy</Link> for more information.
        </Trans>
      </Typography>
    </Container>
  );

  return (
    <Snackbar
      open={open} message={message} action={action}
      TransitionComponent={TransitionUp}
      ContentProps={{ style: { backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary } }}
    />
  );
};

const CookieConsent = () => {
  const location = useLocation();
  const analytics = useAnalytics();
  const { consented, storeConsented } = useCookieConsent();

  useEffect(() => {
    if (consented) {
      // Track the pageview
      const properties = _.pick(location, ['pathname', 'search', 'hash']);
      analytics.trackEvent('pageview', properties);
    }

    if (consented && !window.$crisp) {
      // Load and initialise chat widget (if not already)
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = process.env.REACT_APP_CANVASS_CRISP_WEBSITE_ID;
      const script = document.createElement("script");
      script.src = "https://client.crisp.chat/l.js";
      script.async = true;
      document.getElementsByTagName("head")[0].appendChild(script);
    }
  }, [consented, location, analytics]);

  return <CookieSnackbar open={!consented} onConsented={storeConsented} />
};

const Footer = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Container maxWidth="lg">
      <Box display="flex" alignItems={isSmall ? "flex-start" : "center"} flexDirection={isSmall ? 'column' : 'row'}>
        <Box display="flex" flexDirection={isSmall ? 'column' : 'row'}>
          <Box>
            <Link href={process.env.REACT_APP_CANVASS_PRIVACY_POLICY_HREF} target="_blank">
              {t('Privacy Policy')}
            </Link>
          </Box>
          <Box mt={isSmall ? 0.5 : 0} ml={isSmall ? 0 : 1.5}>
            <Link href={process.env.REACT_APP_CANVASS_TOS_HREF} target="_blank">
              {t('Terms of Service')}
            </Link>
          </Box>
        </Box>
        <Box flexGrow={1} textAlign={isSmall ? "left" : "center"} mt={isSmall ? 0.5 : 0}>
          {t('Copyright © State of Things 2023.')}
        </Box>
        <Box display="flex">
          <Box mr={2}>
            <Link href={process.env.REACT_APP_CANVASS_PRIVACY_POLICY_HREF} target="_blank">
              <img src={SSLIcon} width="75px" height="75px" alt={t('SSL icon')} />
            </Link>
          </Box>
          <Box>
            <Link href={process.env.REACT_APP_CANVASS_PRIVACY_POLICY_HREF} target="_blank">
              <img src={GDPRIcon} width="75px" height="75px" alt={t('GDPR icon')} />
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

const MarketingPage = ({ children }) => {
  return (
    <>
      <Navbar />
      <Container maxWidth="lg">
        {children}
      </Container>
      <Footer />
      <CookieConsent />
    </>
  );
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MarketingPage>
        <Switch>
          <Route
            path="/pricing"
            component={() => <PricingPage />}
          />
          <Route
            path="/"
            component={() => <LandingPage />}
          />
        </Switch>
      </MarketingPage>
    </ThemeProvider>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const startApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <HashRouter>
        <ScrollToTop />
        <App />
      </HashRouter>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

export default startApp;
