import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider } from "@material-ui/core";

import { PrimaryHeading } from './Headings';
import useGoogleTagManager from '../hooks/useGoogleTagManager';

const MARGIN = 8;

const TryItNowArea = () => {
  const { t } = useTranslation();
  const { trackClickToRegister } = useGoogleTagManager();

  const handleClickRegister = () => {
    trackClickToRegister(
      process.env.REACT_APP_CANVASS_REGISTER_HREF,
    );
  };

  return (
    <Box mt={MARGIN} mb={MARGIN}>
      <Divider />
      <Box mt={MARGIN} mb={MARGIN} display="flex" flexDirection="column" alignItems="center" textAlign="center">
        <PrimaryHeading gutterBottom>
          {t('Ready for a faster, smarter way to hire?')}
        </PrimaryHeading>
        <Box mt={2.5}>
          <Button variant="contained" size="large" color="primary" onClick={handleClickRegister}>
            {t("Let's get started")}
          </Button>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

export default TryItNowArea;