import { useCallback, useState } from 'react';

const CONSENT_KEY = 'cookieconsent';

const useCookieConsent = () => {
  const [consented, setConsented] = useState(window.localStorage.getItem(CONSENT_KEY) === 'true');

  const storeConsented = useCallback(() => {
    window.localStorage.setItem(CONSENT_KEY, 'true');
    setConsented(true);
  }, [setConsented]);

  return { consented, storeConsented };
};

export default useCookieConsent;
