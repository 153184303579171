import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Typewriter from 'typewriter-effect';
import { Box, Button, Divider, Grid, Link, Tooltip, Typography, useMediaQuery } from "@material-ui/core";
import { styled, useTheme } from '@material-ui/core/styles';
import EventBusyIcon from '@material-ui/icons/EventBusyRounded';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeopleRounded';
import TrendingUpIcon from '@material-ui/icons/TrendingUpRounded';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import SpeedIcon from '@material-ui/icons/Speed';

import RecordScreenshot from './landing/RecordScreenshot.png';
import InviteScreenshot from './landing/InviteScreenshot.png';
import ReviewScreenshot from './landing/ReviewScreenshot.png';
import HeroPoster from './landing/HeroPoster.png';
import HeroVideo from './landing/HeroVideo.mp4';
import AshbyLogo from './landing/logos/ashby.png';
import ComeetLogo from './landing/logos/comeet.png';
import GreenhouseLogo from './landing/logos/greenhouse.png';
import HiringThingLogo from './landing/logos/hiringthing.png';
import HRPartnerLogo from './landing/logos/hrpartner.png';
import LACRMLogo from './landing/logos/lacrm.png';
import LeverLogo from './landing/logos/lever.png';
import RecruiteeLogo from './landing/logos/recruitee.png';

import PennyProfile from './testimonials/pleo/penny.jpeg';
import PleoLogo from './testimonials/pleo/logo.svg';

import { HeroHeading, PrimaryHeading, SecondaryHeading, TertiaryHeading, QuaternaryHeading } from '../../components/Headings'
import { Testimonial } from '../Testimonials';
import TryItNowArea from '../TryItNowArea';
import useGoogleTagManager from '../../hooks/useGoogleTagManager';

const IntegrationsArea = () => {
  const { t } = useTranslation();

  const handleClickContactUs = e => {
    if (window.$crisp) {
      window.$crisp.push(["do", "chat:open"]);
      e.preventDefault();
    }
  };

  const supportHref = `mailto:${process.env.REACT_APP_CANVASS_SUPPORT_EMAIL}`;

  return (
    <>
      <Box mb={1} textAlign="center">
        <PrimaryHeading>
          {t("We're integrated with your applicant tracking system")}
        </PrimaryHeading>
      </Box>
      <Box p={3}>
        {/* https://v4.mui.com/components/grid/#negative-margin */}
        <Grid container spacing={6} alignItems="center" justify="center">
          <Grid item xs={6} md={3}>
            <img src={GreenhouseLogo} alt={t('Greenhouse logo')} width="100%" />
          </Grid>
          <Grid item xs={6} md={3}>
            <img src={LeverLogo} alt={t('Lever logo')} width="100%" />
          </Grid>
          <Grid item xs={6} md={3}>
            <img src={RecruiteeLogo} alt={t('Recruitee logo')} width="100%" />
          </Grid>
          <Grid item xs={6} md={3}>
            <img src={ComeetLogo} alt={t('Comeet logo')} width="100%" />
          </Grid>
          <Grid item xs={6} md={3}>
            <img src={AshbyLogo} alt={t('Ashby logo')} width="100%" />
          </Grid>
          <Grid item xs={6} md={3}>
            <img src={HiringThingLogo} alt={t('HiringThing logo')} width="100%" />
          </Grid>
          <Grid item xs={6} md={3}>
            <img src={HRPartnerLogo} alt={t('HR Partner logo')} width="100%" />
          </Grid>
          <Grid item xs={6} md={3}>
            <img src={LACRMLogo} alt={t('Less Annoying CRM logo')} width="100%" />
          </Grid>
          <Grid xs={12}>
            <Box textAlign="center">
              <Typography variant="subtitle2" >
                <Trans>
                  Don't see yours? <Link href={supportHref} onClick={handleClickContactUs}>Contact us</Link> as we have several more coming soon!
                </Trans>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const StepNumber = styled(({ label, ...props }) => {
  return (
    <div {...props}>
      {label}
    </div>
  );
})(props => ({
  width: '40px',
  height: '40px',
  lineHeight: '40px',
  borderRadius: '50%',
  backgroundColor: props.theme.palette.primary.main,
  color: props.theme.palette.primary.contrastText,
  textAlign: 'center',
  fontSize: '1.5rem'
}));

const HowItWorksStep = ({ stepNumber, image, title, subtitle, ...props }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box display="flex" flexDirection={isSmall ? 'column' : 'row'} {...props}>
      <Box width={isSmall ? '100%' : '50%'} mb={isSmall ? 2 : 0}>
        <Box minHeight="100%" display="flex" flexDirection="column" justifyContent="center">
          <Box p={isSmall ? 0 : 4}>
            <Box display="flex" alignItems="center">
              <Box mr={1.5}>
                <StepNumber label={stepNumber} />
              </Box>
              <SecondaryHeading color="primary">
                {title}
              </SecondaryHeading>
            </Box>
            <Box mt={2}>
              <TertiaryHeading>
                {subtitle}
              </TertiaryHeading>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        width={isSmall ? '100%' : '50%'}
        display="flex" alignItems="center" justifyItems="center" alignContent="center" justifyContent="center"
      >
        {image}
      </Box>
    </Box>
  );
};

const ReviewStep = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <HowItWorksStep
      stepNumber={3}
      image={<img src={ReviewScreenshot} width={isSmall ? '100%' : '80%'} alt={t('Screenshot of reviewing candidates')} />}
      title={t('Review their responses')}
      subtitle={t('Share candidates with your team and build a shortlist')}
    />
  );
};

const InviteStep = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const phoneIcon = (
    <span>
      <Tooltip title={t('Mobile')} arrow>
        <span><PhoneIphoneIcon /></span>
      </Tooltip>
    </span>
  );
  const tabletIcon = (
    <span>
      <Tooltip title={t('Tablet')} arrow>
        <span><TabletMacIcon /></span>
      </Tooltip>
    </span>
  );
  const desktopIcon = (
    <span>
      <Tooltip title={t('Desktop')} arrow>
        <span><DesktopMacIcon /></span>
      </Tooltip>
    </span>
  );
  return (
    <HowItWorksStep
      stepNumber={2}
      image={<img src={InviteScreenshot} width={isSmall ? '100%' : '80%'} alt={t('Screenshot of inviting candidates')} />}
      title={t('Invite your candidates')}
      subtitle={(
        <>
          <Trans>They record a response in their own time on any device</Trans>&nbsp;({phoneIcon} / {tabletIcon} / {desktopIcon})
        </>
      )}
    />
  );
};

const CreateStep = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <HowItWorksStep
      stepNumber={1}
      image={<img src={RecordScreenshot} width={isSmall ? '100%' : '80%'} alt={t('Screenshot of recording an interview')} />}
      title={t('Record in your browser')}
      subtitle={t('Creating an interview only takes 5 minutes on our platform')}
    />
  );
};

const HowItWorksArea = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box mb={4}>
        <HeroHeading>
          {t('How does it work?')}
        </HeroHeading>
      </Box>
      <CreateStep />
      <Box mt={4} mb={4}>
        <InviteStep />
      </Box>
      <ReviewStep />
    </>
  );
};

const ProductBenefit = ({ icon, heading, content }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <SecondaryHeading color="primary" gutterBottom>
        <Box display="flex" alignItems="center">
          {icon}&nbsp;&nbsp;{heading}
        </Box>
      </SecondaryHeading>
      <TertiaryHeading>
        <Box pr={isSmall ? 0 : 10}>
          {content}
        </Box>
      </TertiaryHeading>
    </>
  );
};

const InlineTypewriter = styled(({ options, ...props }) => {
  return (
    <Box display="inline" {...props}>
      <Typewriter options={options} />
    </Box>
  );
})({
  '& .Typewriter': {
    display: 'inline',
  },
});

const BenefitsArea = () => {
  const { t } = useTranslation();
  return (
    <Box p={3}>
      {/* https://v4.mui.com/components/grid/#negative-margin */}
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <ProductBenefit
            icon={<SpeedIcon />}
            heading={t("Faster time to hire")}
            content={t("Interview more people in 2 days than you would have in 2 weeks")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ProductBenefit
            icon={<EventBusyIcon />}
            heading={t("No scheduling")}
            content={t("Eliminate the back-and-forth and coordination across time zones")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ProductBenefit
            icon={<TrendingUpIcon />}
            heading={t("Scalable process")}
            content={t("Meet hundreds of candidates after recording yourself only once")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ProductBenefit
            icon={<EmojiPeopleIcon />}
            heading={t("Fair assessment")}
            content={t("Evaluate responses fairly using consistent interviews")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const TaglineArea = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box p={3}>
      <TertiaryHeading gutterBottom>
        {t("Fast and flexible approach to interviewing")}
      </TertiaryHeading>
      <QuaternaryHeading>
        <Box style={{ color: theme.palette.text.secondary }}>
          {t('Pre-record your interview questions and invite candidates to respond in their own time')}
        </Box>
      </QuaternaryHeading>
    </Box>
  );
};

const HeroArea = () => {
  const videoRef = useRef();
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isExactlySmall = useMediaQuery(theme.breakpoints.only('sm'));
  const { trackClickToRegister } = useGoogleTagManager();

  const handleClickRegister = () => {
    trackClickToRegister(
      process.env.REACT_APP_CANVASS_REGISTER_HREF,
    );
  };

  useEffect(() => {
    // Shouldn't be necessary but just for good measure...
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, [videoRef]);

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Box display="flex" flexDirection="column" justifyContent="center" minHeight="100%">
          <Box textAlign={isExactlySmall ? 'center' : 'left'}>
            <HeroHeading gutterBottom>
              <Trans>
                Asynchronous<br />video interviews<br />for hiring <InlineTypewriter
                  options={{
                    strings: [t("efficiently"), t("remotely"), t("faster"), t("flexibly"), t("objectively")],
                    autoStart: true,
                    loop: true,
                    skipAddStyles: true
                  }}
                />
              </Trans>
            </HeroHeading>
          </Box>
          <Box mt={2} display="flex" flexDirection={isSmall ? 'column' : 'row'}>
            <Box mr={isSmall ? 0 : 1.5} mb={isSmall ? 1.5 : 0}>
              <Button fullWidth={isSmall} variant="contained" size="large" color="primary" onClick={handleClickRegister}>
                {t("Try it yourself")}
              </Button>
            </Box>
            <Box>
              <Button fullWidth={isSmall} variant="contained" size="large" href={process.env.REACT_APP_CANVASS_SCHEDULE_DEMO_HREF} target="_blank">
                {t("Schedule demo")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item sm={12} md={6}>
        <Box display="flex" flexDirection="row-reverse" mt={isSmall ? 6 : 0}>
          <video ref={videoRef}
            height="100%"
            width="100%"
            autoPlay loop muted playsInline
            style={{ borderRadius: '8px' }}
            poster={HeroPoster}
          >
            <source src={HeroVideo} type="video/mp4" />
          </video>
        </Box>
      </Grid>
    </Grid>
  );
};

const LandingPage = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Box mt={!isSmall ? 4 : 0} mb={10}>
        <HeroArea />
      </Box>
      <Divider />
      <Box mt={4} mb={4}>
        <TaglineArea />
      </Box>
      <Divider />
      <Box mt={5} mb={7}>
        <BenefitsArea />
      </Box>
      <Divider />
      <Box mt={8} mb={5}>
        <Testimonial
          personName="Penny Onchere"
          personTitle="Commercial Talent Partner"
          personProfile={PennyProfile}
          companyName="Pleo"
          companyLogo={PleoLogo}
          paragraphs={[
            <Trans>
              Pleo has been on an amazing growth journey the past year and Canvass has really
              helped us scale our hiring process. For us the future of work is remote working
              and being able to have an asynchronous stage in our process has been great.
            </Trans>,
            <Trans>
              This allows candidates to complete the interview at a time that suits them and when
              they feel the most comfortable. Canvass increased the amount of candidates we could
              have in the process and the speed of which they could complete the process. This has
              been particularly useful for high volume roles.
            </Trans>,
            <Trans>
              The team has been so helpful and are really responsive to feedback and suggestions. It's
              been a pleasure working with them.
            </Trans>,
          ]}
        />
      </Box>
      <Divider />
      <Box mt={10}>
        <HowItWorksArea />
      </Box>
      <Divider />
      <Box mt={10}>
        <IntegrationsArea />
      </Box>
      <TryItNowArea />
    </>
  );
};

export default LandingPage;
