import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, Button, Container, Divider, Drawer, IconButton, Link, Toolbar, Typography, useMediaQuery, useScrollTrigger } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { generatePath } from 'react-router';

import StyledProductName from './StyledProductName';
import useGoogleTagManager from '../hooks/useGoogleTagManager';

const ProductLogo = () => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const fontSize = isExtraSmall ? '3.2rem' : '3.4rem';
  return (
    <Typography variant="h6" component="span" style={{ cursor: 'pointer', fontSize }}>
      <StyledProductName />
    </Typography>
  );
};

const ElevationScroll = ({ children }) => {
  const theme = useTheme();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 3 : 0,
    style: {
      backgroundColor: trigger
        ? theme.palette.background.paper
        : theme.palette.background.default
    }
  });
};

const Navbar = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isNotSmall = useMediaQuery(theme.breakpoints.up('md'));
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { trackClickToRegister } = useGoogleTagManager();

  const menuBackgroundColor = trigger
    ? theme.palette.background.paper
    : theme.palette.background.default;

  const handleClickRegister = () => {
    trackClickToRegister(process.env.REACT_APP_CANVASS_REGISTER_HREF);
  };

  const toolbarButtons = (
    <>
      <Box mr={1}>
        <Button size="large" href={'#' + generatePath('/')}>
          {t('Home')}
        </Button>
        <Button size="large" href={'#' + generatePath('/pricing')}>
          {t('Pricing')}
        </Button>
      </Box>
      <Box mr={2}>
        <Button variant="outlined" size="large" href={process.env.REACT_APP_CANVASS_SIGN_IN_HREF}>
          {t('Sign in')}
        </Button>
      </Box>
      <Box>
        <Button variant="contained" size="large" color="primary" onClick={handleClickRegister}>
          {t('Create account')}
        </Button>
      </Box>
    </>
  );

  const handleToggleMenu = () => {
    setIsMenuOpen(isOpen => !isOpen);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const toolbarMenu = (
    <>
      <IconButton color="inherit" edge="end" onClick={handleToggleMenu}>
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={isMenuOpen}
        onClose={handleCloseMenu}
        PaperProps={{ style: { backgroundColor: menuBackgroundColor } }}
      >
        <div onClick={handleCloseMenu} onKeyDown={handleCloseMenu} style={{ width: '250px' }}>
          <Box mt={6}>
            <Button fullWidth size="large" href={'#' + generatePath('/')}>
              {t('Home')}
            </Button>
          </Box>
          <Box mb={1}>
            <Button fullWidth size="large" href={'#' + generatePath('/pricing')}>
              {t('Pricing')}
            </Button>
          </Box>
          <Divider variant="middle" />
          <Box m={2}>
            <Button fullWidth variant="outlined" size="large" href={process.env.REACT_APP_CANVASS_SIGN_IN_HREF}>
              {t('Sign in')}
            </Button>
          </Box>
          <Box m={2}>
            <Button fullWidth variant="contained" size="large" color="primary" onClick={handleClickRegister}>
              {t('Create account')}
            </Button>
          </Box>
        </div>
      </Drawer>
    </>
  );

  return (
    <ElevationScroll>
      <AppBar position="sticky" elevation={0}>
        <Container maxWidth="lg">
          <Box pt={3} pb={3}>
            <Toolbar disableGutters={true}>
              <Box flexGrow={1}>
                <Link href={'#' + generatePath('/')} style={{ textDecoration: 'none' }}>
                  <ProductLogo />
                </Link>
              </Box>
              {isSmall && toolbarMenu}
              {isNotSmall && toolbarButtons}
            </Toolbar>
          </Box>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
}

export default Navbar;
