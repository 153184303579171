import _ from 'lodash';

import React from 'react';
import { Avatar, Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { styled, useTheme } from '@material-ui/core/styles';

const TestimonialAvatar = styled(({ ...props }) => {
  return (
    <Avatar {...props} />
  );
})(props => ({
  width: props.theme.spacing(props.isSmall ? 18 : 28),
  height: props.theme.spacing(props.isSmall ? 18 : 28),
}));

export const Testimonial = ({ personName, personTitle, personProfile, companyName, companyLogo, paragraphs, ...props }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isExactlyMedium = useMediaQuery(theme.breakpoints.only('md'));

  const paragraphElements = _.flatMap(paragraphs, (paragraph, index) => {
    const isFirst = index <= 0;
    const quoteCharElement = isFirst
      ? <span style={{ position: "absolute", top: -35, left: -40, fontSize: "3rem", color: theme.palette.primary.main }}>❝</span>
      : null;
    const isNotLast = index < paragraphs.length - 1;
    const breakElement = isNotLast ? <br /> : null;
    return [
      <Typography variant="h5" gutterBottom>
        {quoteCharElement}
        {paragraph}
      </Typography>,
      breakElement,
    ];
  });

  return (
    <Box {...props}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={7}>
          <Box position="relative" ml={4} mr={2} fontStyle="italic" textAlign={isSmall ? "left" : "justify"}>
            {paragraphElements}
          </Box>
        </Grid>
        <Grid item xs={12} md={4} lg={5}>
          <Box
            mt={isSmall ? 1.5 : 0}
            display="flex"
            flexDirection={isExactlyMedium ? "column" : "row"}
            alignItems="center"
            justifyItems="center"
            alignContent="center"
            justifyContent="center"
            minHeight="100%"
          >
            <TestimonialAvatar src={personProfile} alt={personName} isSmall={isSmall} />
            <Box ml={isExactlyMedium ? 0 : 3.5} mt={isExactlyMedium ? 2 : 0} textAlign={isExactlyMedium ? "center" : "left"}>
              <Typography variant="subtitle1" style={{ fontSize: isSmall ? "1.2rem" : "1.4rem" }}>
                {personName}
              </Typography>
              <Typography variant="subtitle2" style={{ fontSize: isSmall ? "0.7rem" : "0.8rem" }}>
                {personTitle}
              </Typography>
              <Box mt={isExactlyMedium ? 3 : 2}>
                <img src={companyLogo} alt={companyName} style={{ maxWidth: "150px"}}/>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};