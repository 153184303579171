import React from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export const HeroHeading = ({ children, ...props }) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isExactlyMedium = useMediaQuery(theme.breakpoints.only('md'));
  const fontSize = isExactlyMedium ? '2.9rem' : (isExtraSmall ? '2.5rem' : '3.4rem');
  return (
    <Typography variant="h5" style={{ fontSize, fontWeight: 600 }} {...props}>
      {children}
    </Typography>
  );
};

export const PrimaryHeading = ({ children, ...props }) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const fontSize = isExtraSmall ? '2.2rem' : '2.4rem';
  return (
    <Typography variant="h6" style={{ fontSize }} {...props}>
      {children}
    </Typography>
  );
};

export const SecondaryHeading = ({ children, ...props }) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const fontSize = isExtraSmall ? '1.8rem' : '2rem';
  return (
    <Typography variant="h6" style={{ fontSize }} {...props}>
      {children}
    </Typography>
  );
};

export const TertiaryHeading = ({ children, ...props }) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const fontSize = isExtraSmall ? '1.6rem' : '1.8rem';
  return (
    <Typography variant="h6" style={{ fontSize }} {...props}>
      {children}
    </Typography>
  );
};

export const QuaternaryHeading = ({ children, ...props }) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const fontSize = isExtraSmall ? '1.4rem' : '1.6rem';
  return (
    <Typography variant="h6" style={{ fontSize }} {...props}>
      {children}
    </Typography>
  );
};
