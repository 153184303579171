import _ from 'lodash';
import amplitude from 'amplitude-js';

const addProperties = properties => {
  const newProperties = _.clone(properties);
  newProperties.app = process.env.REACT_APP_CANVASS_APP || 'unknown';
  return newProperties;
}

class ConsoleAnalytics {

  trackEvent(eventType, properties) {
    const eventProperties = addProperties(properties);
    console.log('analytics', eventType, eventProperties);
  }

}

class AmplitudeAnalytics {

  constructor(instance) {
    this.instance = instance;
  }

  trackEvent(eventType, properties) {
    const eventProperties = addProperties(properties);
    this.instance.logEvent(eventType, eventProperties);
  }

}

let analytics = null;

const useAnalytics = () => {
  if (_.isNull(analytics)) {
    const amplitudeKey = process.env.REACT_APP_CANVASS_AMPLITUDE_API_KEY;
    if (amplitudeKey) {
      const instance = amplitude.getInstance();
      instance.init(amplitudeKey);
      analytics = new AmplitudeAnalytics(instance);
    } else {
      analytics = new ConsoleAnalytics();
    }
  }
  return analytics;
};

export default useAnalytics;