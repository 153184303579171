import React, { useState } from 'react';
import { styled, useTheme } from '@material-ui/core/styles';
import { Box, Grid, Link, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Trans, useTranslation } from 'react-i18next';
import useAnalytics from '../hooks/useAnalytics';
import useCookieConsent from '../hooks/useCookieConsent';

const PlanTile = ({ name, description, pricing, ...props }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Paper elevation={3} {...props}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        minHeight={isSmall ? '0px' : '325px'}
        p={3}
      >
        <Box mb={2} textAlign="center">
          <Typography variant="h6" style={{ fontSize: '1.6rem' }}>
            {name}
          </Typography>
        </Box>
        <Box
          flexGrow={1}
          pb={isSmall ? 2 : 0}
          pl={isSmall ? 2 : 0}
          pr={isSmall ? 2 : 0}
          textAlign="center"
          display="flex"
          alignItems="center"
        >
          <Typography variant="body1" style={{ fontSize: '1.2rem' }}>
            {description}
          </Typography>
        </Box>
        <Box mt={2} display="flex" flexDirection="column" alignItems="center">
          {pricing}
        </Box>
      </Box>
    </Paper>
  );
};

const MonthlyAmount = styled(({ amount, ...props }) =>
  <Typography variant="subtitle2" {...props}>
    <Trans>{amount} / month</Trans>
  </Typography>
)({
  fontSize: '1.4rem'
});

const YearlyAmount = styled(({ amount, ...props }) =>
  <Typography variant="subtitle2" {...props}>
    <Trans>{amount} / year</Trans>
  </Typography>
)({
  fontSize: '1.4rem'
});

const PerCandidateAmount = styled(({ amount, ...props }) =>
  <Typography variant="subtitle2" {...props}>
    <Trans>{amount} / candidate</Trans>
  </Typography>
)({
  fontSize: '1.4rem'
});

const PlanTypeBox = ({ label }) =>
  <Box width="145px">
    <Typography variant="subtitle1">
      {label}
    </Typography>
  </Box>;

const SelectPlanType = ({ planType, setPlanType }) => {
  const { t } = useTranslation();
  const analytics = useAnalytics();
  const { consented } = useCookieConsent();

  const handleChangePlanType = (event, newPlanType) => {
    if (newPlanType) {
      setPlanType(newPlanType);
      if (consented) {
        analytics.trackEvent('planview', { newPlanType });
      }
    }
  };

  return (
    <ToggleButtonGroup size="small" value={planType} exclusive onChange={handleChangePlanType}>
      <ToggleButton value="purchase">
        <PlanTypeBox label={t('Pay-as-you-go')} />
      </ToggleButton>
      <ToggleButton value="subscription">
        <PlanTypeBox label={t('Subscription')} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const FrequencyBox = ({ label }) =>
  <Box width="100px">
    <Typography variant="subtitle1">
      {label}
    </Typography>
  </Box>;

const SelectFrequency = ({ frequency, setFrequency }) => {
  const { t } = useTranslation();
  const analytics = useAnalytics();
  const { consented } = useCookieConsent();

  const handleChangeFrequency = (event, newFrequency) => {
    if (newFrequency) {
      setFrequency(newFrequency);
      if (consented) {
        analytics.trackEvent('frequencyview', { newFrequency });
      }
    }
  };

  return (
    <ToggleButtonGroup size="small" value={frequency} exclusive onChange={handleChangeFrequency}>
      <ToggleButton value="monthly">
        <FrequencyBox label={t('Monthly')} />
      </ToggleButton>
      <ToggleButton value="yearly">
        <FrequencyBox label={t('Yearly')} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const CurrencyBox = ({ symbol }) =>
  <Box width="40px">
    <Typography variant="subtitle1">
      {symbol}
    </Typography>
  </Box>;

const SelectCurrency = ({ currency, setCurrency }) => {
  const analytics = useAnalytics();
  const { consented } = useCookieConsent();

  const handleChangeCurrency = (event, newCurrency) => {
    if (newCurrency) {
      setCurrency(newCurrency);
      if (consented) {
        analytics.trackEvent('currencyview', { newCurrency });
      }
    }
  };

  return (
    <ToggleButtonGroup size="small" value={currency} exclusive onChange={handleChangeCurrency}>
      <ToggleButton value="USD">
        <CurrencyBox symbol="$" />
      </ToggleButton>
      <ToggleButton value="GBP">
        <CurrencyBox symbol="£" />
      </ToggleButton>
      <ToggleButton value="EUR">
        <CurrencyBox symbol="€" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const PURCHASE_AMOUNTS = {
  // development
  'price_1KltRELo2G9LdV5dIs2yyx7l': [5.00, 'USD'],
  'price_1KltRELo2G9LdV5dNPoOvSYY': [4.00, 'GBP'],
  'price_1KltRELo2G9LdV5dRB9Q85bh': [4.50, 'EUR'],
  'price_1KltSFLo2G9LdV5dXx6EXHKs': [4.00, 'USD'],
  'price_1KltSFLo2G9LdV5dhDnz44Oj': [3.20, 'GBP'],
  'price_1KltSFLo2G9LdV5dchDya44c': [3.60, 'EUR'],
  'price_1KltT4Lo2G9LdV5dalYUDPTc': [3.00, 'USD'],
  'price_1KltT4Lo2G9LdV5d7nS0AnCT': [2.40, 'GBP'],
  'price_1KltT4Lo2G9LdV5dBSoLBvtR': [2.70, 'EUR'],
  // production
  'price_1KltWhLo2G9LdV5dzycXgHUW': [5.00, 'USD'],
  'price_1KltWhLo2G9LdV5dk02ROj45': [4.00, 'GBP'],
  'price_1KltWhLo2G9LdV5d6Nm4SBKh': [4.50, 'EUR'],
  'price_1KltWmLo2G9LdV5ddMPkiNZV': [4.00, 'USD'],
  'price_1KltWmLo2G9LdV5dBwJS26Kb': [3.20, 'GBP'],
  'price_1KltWmLo2G9LdV5d2hyczUGa': [3.60, 'EUR'],
  'price_1KltWqLo2G9LdV5dZlQ3mj9N': [3.00, 'USD'],
  'price_1KltWqLo2G9LdV5dtHdxh0zt': [2.40, 'GBP'],
  'price_1KltWqLo2G9LdV5dsSU0EuoJ': [2.70, 'EUR'],
};

const SUBSCRIPTION_AMOUNTS = {
  // development
  'price_1JpJNuLo2G9LdV5daDlyRCpS': [90.00, 'USD'],
  'price_1JpVF6Lo2G9LdV5df85BoIbe': [72.00, 'GBP'],
  'price_1JqP5jLo2G9LdV5dUEZ3RdUg': [81.00, 'EUR'],
  'price_1JpJOQLo2G9LdV5dW6AMubB0': [180.00, 'USD'],
  'price_1JpVSILo2G9LdV5dXclBwKlL': [144.00, 'GBP'],
  'price_1JqP7fLo2G9LdV5dLazoxOmU': [162.00, 'EUR'],
  'price_1KEFCCLo2G9LdV5d5A8PlwsX': [360.00, 'USD'],
  'price_1KEFCCLo2G9LdV5do4iZsJZo': [288.00, 'GBP'],
  'price_1KEFCCLo2G9LdV5dJXLSyha8': [324.00, 'EUR'],
  'price_1JpJNuLo2G9LdV5d1aGUsoGd': [900.00, 'USD'],
  'price_1JpVFjLo2G9LdV5dERAUK1js': [720.00, 'GBP'],
  'price_1JqP5yLo2G9LdV5dC3M399VQ': [810.00, 'EUR'],
  'price_1JpJOQLo2G9LdV5dGhht3uHt': [1800.00, 'USD'],
  'price_1JpVSULo2G9LdV5dzXdLNAJO': [1440.00, 'GBP'],
  'price_1JqP8CLo2G9LdV5dvBdjQGtD': [1620.00, 'EUR'],
  'price_1KEFCCLo2G9LdV5dl0L7Pjcu': [3600.00, 'USD'],
  'price_1KEFCCLo2G9LdV5dZMqJ7LwH': [2880.00, 'GBP'],
  'price_1KEFCDLo2G9LdV5dKrCPTiuZ': [3240.00, 'EUR'],
  // production
  'price_1JpYYPLo2G9LdV5dwl27NYoS': [90.00, 'USD'],
  'price_1JpYYPLo2G9LdV5dMqFU5wyA': [72.00, 'GBP'],
  'price_1JqP0iLo2G9LdV5dC6mukqGu': [81.00, 'EUR'],
  'price_1JpYYVLo2G9LdV5dH9o4mpSA': [180.00, 'USD'],
  'price_1JpYYVLo2G9LdV5dWlIOh0Pu': [144.00, 'GBP'],
  'price_1JqP3ULo2G9LdV5dJlP6G9vp': [162.00, 'EUR'],
  'price_1KEFCMLo2G9LdV5dk7rZxdPM': [360.00, 'USD'],
  'price_1KEFCLLo2G9LdV5dJe6gtci1': [288.00, 'GBP'],
  'price_1KEFCLLo2G9LdV5dh1dk5DEU': [324.00, 'EUR'],
  'price_1JpYYPLo2G9LdV5dVxQFCn87': [900.00, 'USD'],
  'price_1JpYYPLo2G9LdV5donZQZqdx': [720.00, 'GBP'],
  'price_1JqP1OLo2G9LdV5dl81XQqy6': [810.00, 'EUR'],
  'price_1JpYYVLo2G9LdV5dp72K4NgW': [1800.00, 'USD'],
  'price_1JpYYULo2G9LdV5d68NbRznY': [1440.00, 'GBP'],
  'price_1JqP3lLo2G9LdV5d933NlNeb': [1620.00, 'EUR'],
  'price_1KEFCLLo2G9LdV5d1nhlLNIA': [3600.00, 'USD'],
  'price_1KEFCLLo2G9LdV5dmOeN7kac': [2880.00, 'GBP'],
  'price_1KEFCLLo2G9LdV5dSNcepGVP': [3240.00, 'EUR'],
};

const PRICING_TREE = {
  'development': {
    'purchase': {
      'small': {
        'USD': 'price_1KltRELo2G9LdV5dIs2yyx7l',
        'GBP': 'price_1KltRELo2G9LdV5dNPoOvSYY',
        'EUR': 'price_1KltRELo2G9LdV5dRB9Q85bh',
      },
      'medium': {
        'USD': 'price_1KltSFLo2G9LdV5dXx6EXHKs',
        'GBP': 'price_1KltSFLo2G9LdV5dhDnz44Oj',
        'EUR': 'price_1KltSFLo2G9LdV5dchDya44c',
      },
      'large': {
        'USD': 'price_1KltT4Lo2G9LdV5dalYUDPTc',
        'GBP': 'price_1KltT4Lo2G9LdV5d7nS0AnCT',
        'EUR': 'price_1KltT4Lo2G9LdV5dBSoLBvtR',
      },
    },
    'subscription': {
      'monthly': {
        'small': {
          'USD': 'price_1JpJNuLo2G9LdV5daDlyRCpS',
          'GBP': 'price_1JpVF6Lo2G9LdV5df85BoIbe',
          'EUR': 'price_1JqP5jLo2G9LdV5dUEZ3RdUg',
        },
        'medium': {
          'USD': 'price_1JpJOQLo2G9LdV5dW6AMubB0',
          'GBP': 'price_1JpVSILo2G9LdV5dXclBwKlL',
          'EUR': 'price_1JqP7fLo2G9LdV5dLazoxOmU',
        },
        'large': {
          'USD': 'price_1KEFCCLo2G9LdV5d5A8PlwsX',
          'GBP': 'price_1KEFCCLo2G9LdV5do4iZsJZo',
          'EUR': 'price_1KEFCCLo2G9LdV5dJXLSyha8',
        },
      },
      'yearly': {
        'small': {
          'USD': 'price_1JpJNuLo2G9LdV5d1aGUsoGd',
          'GBP': 'price_1JpVFjLo2G9LdV5dERAUK1js',
          'EUR': 'price_1JqP5yLo2G9LdV5dC3M399VQ',
        },
        'medium': {
          'USD': 'price_1JpJOQLo2G9LdV5dGhht3uHt',
          'GBP': 'price_1JpVSULo2G9LdV5dzXdLNAJO',
          'EUR': 'price_1JqP8CLo2G9LdV5dvBdjQGtD',
        },
        'large': {
          'USD': 'price_1KEFCCLo2G9LdV5dl0L7Pjcu',
          'GBP': 'price_1KEFCCLo2G9LdV5dZMqJ7LwH',
          'EUR': 'price_1KEFCDLo2G9LdV5dKrCPTiuZ',
        },
      }
    }
  },
  'production': {
    'purchase': {
      'small': {
        'USD': 'price_1KltWhLo2G9LdV5dzycXgHUW',
        'GBP': 'price_1KltWhLo2G9LdV5dk02ROj45',
        'EUR': 'price_1KltWhLo2G9LdV5d6Nm4SBKh',
      },
      'medium': {
        'USD': 'price_1KltWmLo2G9LdV5ddMPkiNZV',
        'GBP': 'price_1KltWmLo2G9LdV5dBwJS26Kb',
        'EUR': 'price_1KltWmLo2G9LdV5d2hyczUGa',
      },
      'large': {
        'USD': 'price_1KltWqLo2G9LdV5dZlQ3mj9N',
        'GBP': 'price_1KltWqLo2G9LdV5dtHdxh0zt',
        'EUR': 'price_1KltWqLo2G9LdV5dsSU0EuoJ',
      },
    },
    'subscription': {
      'monthly': {
        'small': {
          'USD': 'price_1JpYYPLo2G9LdV5dwl27NYoS',
          'GBP': 'price_1JpYYPLo2G9LdV5dMqFU5wyA',
          'EUR': 'price_1JqP0iLo2G9LdV5dC6mukqGu',
        },
        'medium': {
          'USD': 'price_1JpYYVLo2G9LdV5dH9o4mpSA',
          'GBP': 'price_1JpYYVLo2G9LdV5dWlIOh0Pu',
          'EUR': 'price_1JqP3ULo2G9LdV5dJlP6G9vp',
        },
        'large': {
          'USD': 'price_1KEFCMLo2G9LdV5dk7rZxdPM',
          'GBP': 'price_1KEFCLLo2G9LdV5dJe6gtci1',
          'EUR': 'price_1KEFCLLo2G9LdV5dh1dk5DEU',
        },
      },
      'yearly': {
        'small': {
          'USD': 'price_1JpYYPLo2G9LdV5dVxQFCn87',
          'GBP': 'price_1JpYYPLo2G9LdV5donZQZqdx',
          'EUR': 'price_1JqP1OLo2G9LdV5dl81XQqy6',
        },
        'medium': {
          'USD': 'price_1JpYYVLo2G9LdV5dp72K4NgW',
          'GBP': 'price_1JpYYULo2G9LdV5d68NbRznY',
          'EUR': 'price_1JqP3lLo2G9LdV5d933NlNeb',
        },
        'large': {
          'USD': 'price_1KEFCLLo2G9LdV5d1nhlLNIA',
          'GBP': 'price_1KEFCLLo2G9LdV5dmOeN7kac',
          'EUR': 'price_1KEFCLLo2G9LdV5dSNcepGVP',
        },
      }
    }
  }
};

const getSubscriptionPriceId = (frequency, plan, currency) =>
  PRICING_TREE[process.env.NODE_ENV]['subscription'][frequency][plan][currency];

const getPurchasePriceId = (plan, currency) =>
  PRICING_TREE[process.env.NODE_ENV]['purchase'][plan][currency];

const getNavigatorLanguages = () => {
  // https://github.com/supukarmin/navigator-languages
  if (typeof navigator === 'object') {
    var t = 'anguage', n = navigator, f;
    f = n['l' + t + 's'];
    return f && f.length ? f : (t = n['l' + t] ||
      n['browserL' + t] ||
      n['userL' + t]) ? [t] : t;
  }
};

const getLanguage = () => {
  const languages = getNavigatorLanguages();
  return languages ? languages[0] : 'en-US';
};

const formatPlanAmount = (amount, currency) => {
  // Display no minor units at all when there aren't any, or
  // display them to two decimal places...
  const language = getLanguage();
  const formattedAmount = new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
  }).format(amount);
  if (formattedAmount.indexOf('.') < 0) {
    return formattedAmount;
  }
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  }).format(amount);
};

const buildSubscriptionAmount = (amount, currency, frequency) => {
  switch (frequency) {
    case 'monthly':
      return <MonthlyAmount amount={formatPlanAmount(amount, currency)} />;
    case 'yearly':
      return <YearlyAmount amount={formatPlanAmount(amount, currency)} />;
    default:
      throw new Error(`Unexpected subscription frequency: ${frequency}`);
  }
};

const ContactUs = styled(({ ...props }) => {
  const { t } = useTranslation();

  const handleContactUs = e => {
    if (window.$crisp) {
      window.$crisp.push(["do", "chat:open"]);
      e.preventDefault();
    }
  };

  return (
    <Typography variant="subtitle2" {...props}>
      <Link href={`mailto:${process.env.REACT_APP_CANVASS_SUPPORT_EMAIL}`} onClick={handleContactUs}>
        {t('Contact us')}
      </Link>
    </Typography>
  );
})({
  fontSize: '1.4rem'
});

const PlanGrid = ({ ...props }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [planType, setPlanType] = useState('purchase');
  const [frequency, setFrequency] = useState('monthly');
  const [currency, setCurrency] = useState('USD');

  const [smallSubscriptionAmount, smallSubscriptionCurrency] = SUBSCRIPTION_AMOUNTS[
    getSubscriptionPriceId(frequency, 'small', currency)
  ];
  const formattedSmallSubscriptionAmount = buildSubscriptionAmount(smallSubscriptionAmount, smallSubscriptionCurrency, frequency);

  const [mediumSubscriptionAmount, mediumSubscriptionCurrency] = SUBSCRIPTION_AMOUNTS[
    getSubscriptionPriceId(frequency, 'medium', currency)
  ];
  const formattedMediumSubscriptionAmount = buildSubscriptionAmount(mediumSubscriptionAmount, mediumSubscriptionCurrency, frequency);

  const [largeSubscriptionAmount, largeSubscriptionCurrency] = SUBSCRIPTION_AMOUNTS[
    getSubscriptionPriceId(frequency, 'large', currency)
  ];
  const formattedLargeSubscriptionAmount = buildSubscriptionAmount(largeSubscriptionAmount, largeSubscriptionCurrency, frequency);

  const [smallPurchaseAmount, smallPurchaseCurrency] = PURCHASE_AMOUNTS[getPurchasePriceId('small', currency)];
  const formattedSmallPurchaseAmount = (
    <PerCandidateAmount amount={formatPlanAmount(smallPurchaseAmount, smallPurchaseCurrency)} />
  );

  const [mediumPurchaseAmount, mediumPurchaseCurrency] = PURCHASE_AMOUNTS[getPurchasePriceId('medium', currency)];
  const formattedMediumPurchaseAmount = (
    <PerCandidateAmount amount={formatPlanAmount(mediumPurchaseAmount, mediumPurchaseCurrency)} />
  );

  const [largePurchaseAmount, largePurchaseCurrency] = PURCHASE_AMOUNTS[getPurchasePriceId('large', currency)];
  const formattedLargePurchaseAmount = (
    <PerCandidateAmount amount={formatPlanAmount(largePurchaseAmount, largePurchaseCurrency)} />
  );

  const isSubscription = planType === 'subscription';

  return (
    <Box {...props}>
      <Box
        display="flex"
        flexDirection={isSmall ? 'column' : 'row'}
        alignItems="center"
        justifyContent="center"
      >
        <Box flexGrow={1}>
          <Box
            display="flex"
            flexDirection={isSmall ? 'column' : 'row'}
            alignItems="center"
          >
            <Box>
              <Paper>
                <SelectPlanType
                  planType={planType}
                  setPlanType={setPlanType}
                />
              </Paper>
            </Box>
            {
              isSubscription &&
              <Box mt={isSmall ? 2 : 0} ml={!isSmall ? 2 : 0}>
                <Paper>
                  <SelectFrequency
                    frequency={frequency}
                    setFrequency={setFrequency}
                  />
                </Paper>
              </Box>
            }
          </Box>
        </Box>
        <Box mt={isSmall ? 2 : 0}>
          <Paper>
            <SelectCurrency
              currency={currency}
              setCurrency={setCurrency}
            />
          </Paper>
        </Box>
      </Box>
      <Box mt={2}>
        {
          !isSubscription &&
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <PlanTile
                  name={t('10-pack')}
                  description={
                    <Trans>
                      Credits for interviewing<br /><b>10 candidates</b>
                    </Trans>
                  }
                  pricing={formattedSmallPurchaseAmount}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <PlanTile
                  name={t('20-pack')}
                  description={
                    <Trans>
                      Credits for interviewing<br /><b>20 candidates</b>
                    </Trans>
                  }
                  pricing={formattedMediumPurchaseAmount}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <PlanTile
                  name={t('40-pack')}
                  description={
                    <Trans>
                      Credits for interviewing<br /><b>40 candidates</b>
                    </Trans>
                  }
                  pricing={formattedLargePurchaseAmount}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <PlanTile
                  name={t('Larger packs')}
                  description={
                    <Trans>
                      Discounted rates available for greater volume
                    </Trans>
                  }
                  pricing={<ContactUs />}
                />
              </Grid>
            </Grid>
            <Box mt={2} textAlign="center">
              <Typography variant="subtitle1" style={{ fontSize: '1.1rem' }}>
                <Trans>
                  <span style={{ textDecoration: 'underline' }}>Purchase additional credits as needed</span> without any subscription necessary
                </Trans>
              </Typography>
            </Box>
          </>
        }
        {
          isSubscription &&
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <PlanTile
                  name={t('Starter')}
                  description={
                    <Trans>
                      For companies having<br /><b>under 50 employees</b><br /><br />
                      Unlimited usage
                    </Trans>
                  }
                  pricing={formattedSmallSubscriptionAmount}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <PlanTile
                  name={t('Growth')}
                  description={
                    <Trans>
                      For companies having<br /><b>50 to 250 employees</b><br /><br />
                      Unlimited usage
                    </Trans>
                  }
                  pricing={formattedMediumSubscriptionAmount}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <PlanTile
                  name={t('Scale')}
                  description={
                    <Trans>
                      For companies having<br /><b>251 to 500 employees</b><br /><br />
                      Unlimited usage
                    </Trans>
                  }
                  pricing={formattedLargeSubscriptionAmount}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <PlanTile
                  name={t('Custom')}
                  description={
                    <Trans>
                      For larger companies, high volume processes, recruitment agencies, and other use cases
                    </Trans>
                  }
                  pricing={<ContactUs />}
                />
              </Grid>
            </Grid>
            {
              frequency === 'monthly' &&
              <Box mt={2} textAlign="center">
                <Typography variant="subtitle1" style={{ fontSize: '1.1rem' }}>
                  <Trans>
                    <span style={{ textDecoration: 'underline' }}>Cancel at any time</span> or switch to yearly billing for a discount
                  </Trans>
                </Typography>
              </Box>
            }
          </>
        }
      </Box>
    </Box>
  );
};

export default PlanGrid;