import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';

import BrianProfile from './testimonials/havenly/brian.jpeg';
import HavenlyLogo from './testimonials/havenly/logo.svg';

import PlanGrid from '../PlanGrid';
import TryItNowArea from '../TryItNowArea';
import { PrimaryHeading, QuaternaryHeading } from '../Headings';
import { Testimonial } from '../Testimonials';

const Feature = ({ header, bullets }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box ml={isSmall ? 5 : 0}>
      <Typography variant="subtitle1" gutterBottom style={{ fontSize: '1.2rem', fontWeight: 600 }}>
        {header}
      </Typography>
      <List dense={true}>
        {bullets.map(bullet =>
          <ListItem key={bullet}>
            <ListItemIcon>
              <CheckIcon color='primary' />
            </ListItemIcon>
            <ListItemText>
              <Box style={{ fontSize: '1.1rem' }}>
                {bullet}
              </Box>
            </ListItemText>
          </ListItem>
        )}
      </List>
    </Box>
  );
};

const FeaturesArea = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box mt={9} mb={4} textAlign="center">
        <PrimaryHeading gutterBottom>
          {t('All features included')}
        </PrimaryHeading>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Feature
            header={t('Create an interview in minutes')}
            bullets={[
              t('Video and/or text questions'),
              t('Video intro and outro'),
              t('Adjustable thinking time, answer length, and number of retakes'),
              t('Reusable content library'),
              t('Branded for your company'),
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Feature
            header={t('Invite candidates at scale')}
            bullets={[
              t('Individual and bulk CSV invites'),
              t('Custom email templates'),
              t('Flexible invite, reminder, and response received notification campaigns'),
              t('Self-serve interview link'),
              t('Integrated with your ATS'),
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Feature
            header={t('Review responses as a team')}
            bullets={[
              t('Collaborate with colleagues'),
              t('Share responses internally'),
              t('Watch on 1x, 1.5x or 2x speed'),
              t('Manage access and notifications'),
              t('Automated transcription'),
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Feature
            header={t('Seamless candidate experience')}
            bullets={[
              t('Mobile, Desktop and Tablet'),
              t('No registration required'),
              t('No software to download'),
              t('Automated closed captions'),
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Feature
            header={t('Robust platform')}
            bullets={[
              t('API and web hooks'),
              t('GDPR compliance and automation'),
              t('Encryption in transit and at rest'),
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Feature
            header={t('World class support')}
            bullets={[
              t('Personalised onboarding'),
              t('Chat and email support'),
              t('Speedy response times')
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
};

const PricingArea = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box mt={!isSmall ? 4 : 0}>
      <Box mb={6} textAlign="center">
        <PrimaryHeading>
          {t('Affordable and flexible to your needs')}
        </PrimaryHeading>
        <Box mt={isExtraSmall ? 4 : 0}>
          <QuaternaryHeading>
            <Trans>
              <span style={{ textDecoration: 'underline' }}>No setup fees</span> and you'll start with <span style={{ textDecoration: 'underline' }}>{{ interviewCredits: process.env.REACT_APP_CANVASS_WELCOME_PACK_SIZE }} interview credits</span> for FREE 🎉
            </Trans>
          </QuaternaryHeading>
        </Box>
      </Box>
      <PlanGrid />
    </Box>
  );
};

const PricingPage = () => {
  return (
    <>
      <PricingArea />
      <FeaturesArea />
      <Box mt={8}>
        <Divider />
        <Box mt={8}>
          <Testimonial
            personName="Brian Nishi"
            personTitle="Senior Director, People & Culture"
            personProfile={BrianProfile}
            companyName="Havenly"
            companyLogo={HavenlyLogo}
            paragraphs={[
              <Trans>
                We run the largest virtual interior design business in the US, and rely on exceptional client experiences
                between designers and clients.
              </Trans>,
              <Trans>
                Canvass gives us the ability to scale our screening process while keeping
                our onboarding and recruiting team relatively small. The platform integrates well with our ATS and was
                exactly what we were looking for.
              </Trans>,
              <Trans>
                While the platform is reliable and easy for candidates to navigate,
                I can't say enough about the quality of the customer service; it was an unexpected bonus!
              </Trans>
            ]}
          />
        </Box>
      </Box>
      <TryItNowArea />
    </>
  );
};

export default PricingPage;
